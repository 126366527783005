import { defineStore } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import paymentsService from '@/api/payments';
import { getConfirmSubscriptionErrorMessage, isCardExpired, validateBillingInfo } from '@/utils/payments';
export const usePaymentsStore = defineStore('payments', () => {
    // state
    const subscriptionPlan = ref();
    const currentSubscriptionData = ref();
    const customerData = ref();
    const paymentMethods = ref([]);
    const balance = ref();
    const transactionsList = ref([]);
    const transactionsTotal = ref(0);
    const billingInformation = ref();
    const resourcesData = shallowRef([]);
    const resourcesCategories = ref([]);
    const isSubscriptionsEnabled = ref(false);
    const isRefillEnabled = ref(false);
    const isBalanceLoading = ref(false);
    const isCustomerDataLoading = ref(false);
    const isActivationLoading = ref(false);
    // getters
    const defaultPaymentMethod = computed(() => {
        return paymentMethods.value.find(method => method.default);
    });
    const isDefaultExpired = computed(() => {
        if (!defaultPaymentMethod.value) {
            return false;
        }
        return isCardExpired(defaultPaymentMethod.value);
    });
    const isBillingInformationValid = computed(() => {
        if (!billingInformation.value)
            return true;
        return validateBillingInfo(billingInformation.value);
    });
    // actions
    const getCustomerData = async () => {
        const data = await paymentsService.getCustomerData();
        customerData.value = data;
        return data;
    };
    const getAllCustomerData = async () => {
        isCustomerDataLoading.value = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const promises = [getCustomerData()];
        if (isSubscriptionsEnabled.value && isRefillEnabled.value) {
            promises.push(getSubscriptionData(), getSubscriptionPlan());
        }
        await Promise.all(promises);
        isCustomerDataLoading.value = false;
    };
    const getPaymentMethods = async () => {
        paymentMethods.value = await paymentsService.getMethods();
    };
    const addPaymentMethod = async () => {
        return await paymentsService.getSetupMethodSecret().then(response => response.client_secret);
    };
    const setDefaultMethod = async (id) => {
        await paymentsService.setDefaultMethod(id);
        paymentMethods.value = paymentMethods.value.map(method => {
            if (!method.default && method.id === id) {
                method.default = true;
            }
            else if (method.default && method.id !== id) {
                method.default = false;
            }
            return method;
        });
    };
    const deleteMethod = async (id) => {
        return await paymentsService.deleteMethod(id);
    };
    const payRequest = async (amount, methodId) => {
        return await paymentsService.putPayment({
            amount,
            methodId,
        }).catch((_error) => {
            var _a, _b, _c;
            if (typeof _error === 'string') {
                return {
                    error: _error,
                };
            }
            return {
                error: (_c = (_b = (_a = _error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message,
            };
        });
    };
    const getSubscriptionPlan = async () => {
        const data = await paymentsService.getPaymentPlans();
        subscriptionPlan.value = data[0];
    };
    const getSubscriptionData = async () => {
        currentSubscriptionData.value = await paymentsService.getSubscriptionData();
    };
    const previewSubscription = async (billingPlanId) => {
        return await paymentsService.getSubscriptionPreview(billingPlanId);
    };
    const confirmSubscription = async (methodId, billingPlanId) => {
        return await paymentsService.createNewSubscription(methodId, billingPlanId)
            .catch((_error) => {
            if (_error instanceof Error) {
                return {
                    error: getConfirmSubscriptionErrorMessage(_error.message),
                };
            }
            return { error: 'Server error, please try again later' };
        });
    };
    const resumeSubscription = async () => {
        return await paymentsService.resumeSubscription();
    };
    const pauseSubscription = async () => {
        return await paymentsService.pauseSubscription();
    };
    const getBalance = async () => {
        isBalanceLoading.value = true;
        balance.value = await paymentsService.getBalance();
        isBalanceLoading.value = false;
    };
    const listTransactions = async (paginationObj = {
        limit: 10,
        offset: 0,
    }, filterObject) => {
        const data = await paymentsService.getTransactionsList(paginationObj, filterObject);
        transactionsList.value = data.items;
        transactionsTotal.value = data.total;
    };
    const getCustomerBillingInfo = async () => {
        const data = await paymentsService.getCustomerBillingInfo();
        let _billingInfo = data;
        // check if object has at least one value
        const hasValues = Object.values(_billingInfo).some(val => !!val);
        if (!hasValues) {
            _billingInfo = null;
        }
        else if (hasValues && !_billingInfo.address) {
            // crutch for stripe
            _billingInfo.address = {
                city: '',
                country: '',
                line1: '',
                postal_code: '',
                state: '',
            };
        }
        billingInformation.value = _billingInfo;
    };
    const setCustomerBillingInfo = async (billingInfo) => {
        billingInformation.value = await paymentsService.setCustomerBillingInfo(billingInfo);
    };
    const getResourcesCategories = async () => {
        resourcesCategories.value = await paymentsService.getResourcesCategories();
    };
    const getResourcesData = async (filterObject = {}) => {
        var _a;
        const payload = filterObject;
        if (((_a = payload.date) === null || _a === void 0 ? void 0 : _a.dateTo) && payload.date.dateFrom &&
            payload.date.dateTo instanceof Date && payload.date.dateFrom instanceof Date) {
            payload.date.dateFrom = payload.date.dateFrom.toISOString().split('T')[0];
            payload.date.dateTo = payload.date.dateTo.toISOString().split('T')[0];
        }
        resourcesData.value = await paymentsService.getResourcesData(filterObject);
    };
    const reactivateAccount = async () => {
        isActivationLoading.value = true;
        await paymentsService.reactivateAccount();
        isActivationLoading.value = false;
        return;
    };
    const getPayableStatus = async () => {
        return await paymentsService.getPayableStatus();
    };
    /**
     * Is subscriptions enabled on this env
     */
    const getSubscriptionsFlag = async () => {
        isSubscriptionsEnabled.value = (await paymentsService.getSettingByKey('SUBSCRIPTIONS_ENABLED')).value;
    };
    const getRefillEnabledFlag = async () => {
        isRefillEnabled.value = (await paymentsService.getSettingByKey('REFIL_ENABLED')).value;
    };
    const pollTransaction = async (paymentIntentId, callback, pollInterval = 1500) => {
        const checkTransactionStatus = async () => {
            try {
                const { isPresent } = await paymentsService.pollTransaction({ paymentIntentId });
                if (isPresent) {
                    await callback();
                    return;
                }
                setTimeout(checkTransactionStatus, pollInterval);
            }
            catch (error) {
                console.error('Error checking transaction status:', error);
            }
        };
        checkTransactionStatus();
    };
    return {
        isSubscriptionsEnabled,
        customerData,
        subscriptionPlan,
        currentSubscriptionData,
        billingInformation,
        paymentMethods,
        balance,
        transactionsList,
        transactionsTotal,
        defaultPaymentMethod,
        isDefaultExpired,
        isBillingInformationValid,
        resourcesCategories,
        resourcesData,
        isRefillEnabled,
        isBalanceLoading,
        isCustomerDataLoading,
        isActivationLoading,
        getPayableStatus,
        getCustomerData,
        getAllCustomerData,
        getSubscriptionPlan,
        getSubscriptionData,
        getPaymentMethods,
        addPaymentMethod,
        setDefaultMethod,
        deleteMethod,
        payRequest,
        previewSubscription,
        confirmSubscription,
        resumeSubscription,
        pauseSubscription,
        getBalance,
        listTransactions,
        getCustomerBillingInfo,
        setCustomerBillingInfo,
        getResourcesCategories,
        getResourcesData,
        reactivateAccount,
        getSubscriptionsFlag,
        getRefillEnabledFlag,
        pollTransaction,
    };
});
